import axios, { AxiosError } from 'axios'
import { OrderType } from '../types/OrderType'
import { generateHealthSourceId } from '../utils/Helpers'
import { GiftOrderType } from '../types/GiftOrderType'
import { SendGiftCode } from '../types/SendGiftCode'
import { StatsInputType } from '../types/StatsInputType'
import { ContactType } from '../types/ContactType'

const BASE_URLS = [
    'https://m08yvtgn.ngrok.app',
    //'https://6z7s9k4e.ngrok.app'
]

const RETRY_CODES = [404, 502, 500]
 
const postOrder = async (order: OrderType, baseURL: string) => {
    const endpointURL = `${baseURL}/message`

    const data = await axios({method: 'POST', url: endpointURL, data: order, timeout: 20000}).then((response: any) => {
        return response
    }).catch((error: AxiosError) => {
        return {"status": error.response?.status, "data": error.response?.data}
    })
    return data
}

const postGiftOrder = async (order: GiftOrderType, baseURL: string) => {
    const endpointURL = `${baseURL}/gift_order`

    const data = await axios({method: 'POST', url: endpointURL, data: order, timeout: 20000}).then((response: any) => {
        return response
    }).catch((error: AxiosError) => {
        return {"status": error.response?.status, "data": error.response?.data}
    })
    return data
}

const sendGiftCode = async (order: SendGiftCode, baseURL: string) => {
    const endpointURL = `${baseURL}/send_code`

    const data = await axios({method: 'POST', url: endpointURL, data: order, timeout: 20000}).then((response: any) => {
        return response
    }).catch((error: AxiosError) => {
        return {"status": error.response?.status, "data": error.response?.data}
    })
    return data
}

const getTransmitterHealth = async (baseURL: string) => {
    const endpointURL = `${baseURL}/transmitter`

    const data = {"sourceId": generateHealthSourceId()}

    const { status } = await axios({method: 'POST', url: endpointURL, data: data, timeout: 5000}).then((response: any) => {
        return response
    }).catch((error: AxiosError) => {
        return {"status": error.response?.status}
    })
    return status
}

const postContact = async (contact: ContactType, baseURL: string) => {
    const endpointURL = `${baseURL}/contact`

    const { status } = await axios({method: 'POST', url: endpointURL, data: contact, timeout: 20000}).then((response: any) => {
        return response
    }).catch((error: AxiosError) => {
        return {"status": error.response?.status}
    })
    return status
}

export const getStats = async (statsInput: StatsInputType) => {
    const endpointURL = `${BASE_URLS[0]}/stats`

    const data = await axios({method: 'POST', url: endpointURL, data: statsInput, timeout: 20000}).then((response: any) => {
        return response
    }).catch((error: AxiosError) => {
        return {"status": error.response?.status, "data": error.response?.data}
    })
    return data
}

export const postOrderBalanced = async (order: OrderType) => {
    const numUrls = BASE_URLS.length
    let tryNum = 1
    let index = Math.floor(Math.random() * numUrls)

    while (tryNum <= numUrls) {
        const response = await postOrder(order, BASE_URLS[index])
        if (response.status && !RETRY_CODES.includes(response.status)) {
            return response
        } else if (index + 1 < numUrls) {
            index++
        } else {
            index = 0
        }
        tryNum ++
    }
    return {"status": 0, "data": "ERROR"}
}

export const postGiftOrderBalanced = async (order: GiftOrderType) => {
    const numUrls = BASE_URLS.length
    let tryNum = 1
    let index = Math.floor(Math.random() * numUrls)

    while (tryNum <= numUrls) {
        const response = await postGiftOrder(order, BASE_URLS[index])
        if (response.status && !RETRY_CODES.includes(response.status)) {
            return response
        } else if (index + 1 < numUrls) {
            index++
        } else {
            index = 0
        }
        tryNum ++
    }
    return {"status": 0, "data": "ERROR"}
}

export const sendGiftCodeBalanced = async (order: SendGiftCode) => {
    const numUrls = BASE_URLS.length
    let tryNum = 1
    let index = Math.floor(Math.random() * numUrls)

    while (tryNum <= numUrls) {
        const response = await sendGiftCode(order, BASE_URLS[index])
        if (response.status && !RETRY_CODES.includes(response.status)) {
            return response
        } else if (index + 1 < numUrls) {
            index++
        } else {
            index = 0
        }
        tryNum ++
    }
    return {"status": 0, "data": "ERROR"}
}

export const getTransmitterHealthBalanced = async () => {
    const numUrls = BASE_URLS.length
    let tryNum = 1
    let index = Math.floor(Math.random() * numUrls)

    while (tryNum <= numUrls) {
        const status = await getTransmitterHealth(BASE_URLS[index])
        if (status && !RETRY_CODES.includes(status)) {
            return status
        } else if (index + 1 < numUrls) {
            index++
        } else {
            index = 0
        }
        tryNum ++
    }
    return {"status": 0}
}

export const postContactBalanced = async (contact: ContactType) => {
    const numUrls = BASE_URLS.length
    let tryNum = 1
    let index = Math.floor(Math.random() * numUrls)

    while (tryNum <= numUrls) {
        const status = await postContact(contact, BASE_URLS[index])
        if (status && !RETRY_CODES.includes(status)) {
            return status
        } else if (index + 1 < numUrls) {
            index++
        } else {
            index = 0
        }
        tryNum ++
    }
    return {"status": 0}
}